import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const padding = theme.spacing(2);

    return createStyles({
        paneContent: {
            padding: `${padding}px`,
        },
        formRow: {
            display: 'flex',
            alignItems: 'center',
            margin: `0 -${padding}px`,
            padding: `${theme.spacing()}px ${padding}px`,
            borderBottom: '1px solid rgba(140, 140, 140, 0.5)',
        },
        checkboxRow: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
            paddingBottom: '0.5em',
        },
        combobox: {
            flexGrow: 1,
            '&:nth-child(2)': {
                marginLeft: padding,
            },
        },
    });
});
