import React from 'react';

import { Checkbox } from '@material-ui/core';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import { useStyles } from '../../Themable.hooks';
import { usePrivileges } from '../../../../../../../../state/auth/index.hooks';

interface IOwnProps {
    isPrioritizedDevice: boolean;
    setIsPrioritizedDevice: (bool: boolean) => void;
}
const PrioritizedDeviceCheckbox = ({
    isPrioritizedDevice,
    setIsPrioritizedDevice,
}: IOwnProps) => {
    const classes = useStyles();
    const privileges = usePrivileges();

    if (!privileges.gpsPrioritizedDeviceManagement) return null;

    return (
        <div className={classes.formRow} style={{ marginBottom: '1.5em' }}>
            <div className={classes.checkboxRow}>
                <p>{TranslationHelper.translate('Prioritized Device')}</p>
                <Checkbox
                    color={'primary'}
                    checked={isPrioritizedDevice}
                    onChange={() =>
                        setIsPrioritizedDevice(!isPrioritizedDevice)
                    }
                />
            </div>
        </div>
    );
};

export default PrioritizedDeviceCheckbox;
