import React from 'react';

import ListItem from '@material-ui/core/ListItem';

import { useStyles } from './Themable.hooks';

import {
    IBinaryValue,
    IReport,
    IReportEntity,
} from '../../../../../../state/types';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import ShowImage from './components/ShowImage';

import { ListItemEntityValue } from './components/ListItemEntityValue/ListItemEntityValue';
import ListItemEntityActions from './components/ListItemEntityActions/ListItemEntityActions';
import EntityMetaIcon from '../../../../../../components/IconWithTooltip';

interface IOwnProps {
    report: IReport;
    entity: IReportEntity;
    handleDelete?: (id: string, message?: string) => void;
}

const ReportListItem = ({ report, entity, handleDelete }: IOwnProps) => {
    const classes = useStyles();
    const { preview: previewAction, download: downloadAction } =
        entity.actions ?? {};

    if (previewAction && downloadAction) {
        return (
            <ListItem className={classes.listItem}>
                <ShowImage
                    classes={classes}
                    apiPreview={previewAction.api}
                    apiDownload={downloadAction.api}
                    name={`${TranslationHelper.translate(
                        entity.label as string,
                        'mp'
                    )} (${entity.label})`}
                    download={
                        (entity.value as IBinaryValue)?.name ||
                        (entity.value as string)
                    }
                    valid={!!previewAction.api}
                    type={entity.type}
                    paramName={entity.id}
                    reportId={report.id}
                />
            </ListItem>
        );
    }
    return (
        <ListItem className={classes.listItem}>
            <>
                <div className={classes.itemLabel}>
                    {entity.translatedLabel || entity.label || entity.value}
                    {entity._meta?.icon?.type === 'info' && (
                        <EntityMetaIcon
                            iconId={entity._meta.icon.id}
                            label={entity._meta.icon.label}
                        />
                    )}
                </div>

                <span className={classes.secondaryAction}>
                    <ListItemEntityValue entity={entity} />
                    <ListItemEntityActions
                        report={report}
                        entity={entity}
                        handleDelete={handleDelete}
                    />
                </span>
            </>
        </ListItem>
    );
};

export default ReportListItem;
