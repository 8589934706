import React from 'react';

import Report from '../Report';

import Translator from '../../../../helpers/TranslationHelper';

import { IReport } from '../../../../state/types';
import { Box } from '@material-ui/core';

interface IOwnProps {
    reports: IReport[] | null;
}

const PreviewReports = ({ reports }: IOwnProps) => {
    return (
        <>
            {reports ? (
                reports.map((report, index) => (
                    <Report
                        key={index}
                        report={report}
                        expanded={true}
                        preview={null}
                    />
                ))
            ) : (
                <Box sx={{ p: 3 }}>
                    <p>{Translator.translate('No data to display')}</p>
                </Box>
            )}
        </>
    );
};

export default PreviewReports;
