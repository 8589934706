import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            width: '100%',
        },
        dateTimeDialog: {
            display: 'flow-root',
        },
    })
);
