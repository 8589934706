import React from 'react';

import PreviewPane from './components/PreviewPane';

import DiscoveryGrid from './components/DiscoveryGrid/DiscoveryGrid';
import { useIsGridFullScreenOrAnalytics } from './selectors/index.hooks';
import useDiscoveryLayout from './hooks/useDiscoveryLayout';
import { TStyling } from './components/_utils/types';

interface IOwnProps {
    isMobile: boolean;
}

const getPreviewPaneStyling = (
    isPreviewPaneVisible: boolean,
    isBottomPaneVisible: boolean,
    isGridFullscreen: boolean
): TStyling => {
    return [
        {
            predicate: () =>
                isPreviewPaneVisible &&
                isBottomPaneVisible &&
                !isGridFullscreen,
            style: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 'calc(100% - 420px)',
            },
        },
        {
            predicate: () =>
                isPreviewPaneVisible &&
                !isBottomPaneVisible &&
                !isGridFullscreen,
            style: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 'calc(100% - 420px)',
            },
        },
        {
            predicate: () =>
                isPreviewPaneVisible && isBottomPaneVisible && isGridFullscreen,
            style: {
                display: 'none',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 'calc(100% - 420px)',
            },
        },
    ].find(({ predicate }) => predicate());
};

const DesktopGrid = ({ isMobile }: IOwnProps) => {
    const { layoutClassName, isBottomPaneVisible, isPreviewPaneVisible } =
        useDiscoveryLayout();
    const isGridFullscreen = useIsGridFullScreenOrAnalytics();

    const previewPaneStyling = getPreviewPaneStyling(
        isPreviewPaneVisible,
        isBottomPaneVisible,
        isGridFullscreen
    );

    if (isMobile) {
        return null;
    }

    return (
        <>
            <DiscoveryGrid
                layoutClassName={layoutClassName}
                isBottomPaneVisible={isBottomPaneVisible}
            />

            {previewPaneStyling && (
                <div style={previewPaneStyling.style}>
                    <PreviewPane />
                </div>
            )}
        </>
    );
};

export default DesktopGrid;
