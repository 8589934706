import React, { ReactChild } from 'react';

import { Tooltip } from '@mui/material';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import TranslationHelper from '../../helpers/TranslationHelper';
import { useStyles } from './Themable.hooks';

interface IOwnProps {
    iconId: string;
    label?: string;
}

const icons: { [key: string]: ReactChild } = {
    info: <InfoIcon />,
    gps_fixed: <GpsFixedIcon />,
};
const IconWithTooltip = ({ iconId, label = '' }: IOwnProps) => {
    const classes = useStyles();
    return (
        <Tooltip title={TranslationHelper.translate(label)}>
            <div data-testid="icon" className={classes.icon}>
                {icons[iconId]}
            </div>
        </Tooltip>
    );
};

export default IconWithTooltip;
