import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { default as Paper } from '@material-ui/core/Paper';

import GoogleMapsButton from '../../../../../../components/common/GoogleMapsButton';
import PaneHeader from '../../../../../../components/PaneHeader';
import SourceSetElement from '../SourceSetElementPreview/components/SourceSetElement';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { getHeaderName } from '../../_utils';

type TProps = TConnectableProps & TThemableProps;

class AggregationDetailsPreview extends Component<TProps> {
    public componentDidMount() {
        if (this.props.preview && this.props.preview.previewAction) {
            this.props.fetchAggregationDetails(
                this.props.preview.previewAction
            );
        }
    }

    public render() {
        const { classes, sourceSetElementReportSet } = this.props;

        return (
            sourceSetElementReportSet && (
                <Paper className={classes.paper}>
                    <PaneHeader
                        title={TranslationHelper.translate('Details')}
                        onCloseClick={this.handleCloseClick}
                        renderRightCustomControls={() => (
                            <GoogleMapsButton
                                coordinates={
                                    sourceSetElementReportSet?._meta?.header
                                        ?.coordinates
                                }
                            />
                        )}
                    />
                    <SourceSetElement
                        sourceSetElementReportSet={sourceSetElementReportSet}
                        getHeaderName={getHeaderName}
                    />
                </Paper>
            )
        );
    }

    private handleCloseClick = () => {
        this.props.closePreview(this.props.creatorLevel);
    };
}

export default compose(
    Themable,
    Connectable
)(AggregationDetailsPreview) as ComponentType<{}>;
