import React from 'react';

import { IPrivileges } from 'src/app/state/auth';
import { errorMessageHandler } from '../../../../../../../helpers/errorMessageHandler';
import {
    ObjectLatestParamsRequest,
    restGetMonitoredObjectsLatestParam,
} from '../../../../../../../services/registers/monitored';
import { customAction } from '../../../../../../../state/_actions';
import { IParameterRest } from '../../../../../../../state/app/mappings';
import { IPreviewAction } from '../../../../../../../state/ui/discovery/types';
import { IParameterMapping } from '../../../../../../../state/ui/forms';

export const initialDataPromises = (
    dispatch: React.Dispatch<any>,
    callbackForms: {
        setBlankForm: (form: IParameterMapping[]) => void;
        setInitialBlankForm: (form: IParameterMapping[]) => void;
    },
    showNotification: (success: boolean, message: string) => void,
    privileges: IPrivileges,
    monitoredObjectId: string | null | undefined,
    action: IPreviewAction
) => {
    const getMappingsAction: IPreviewAction = {
        ...action,
        label: 'GET',
        method: 'GET',
    };
    const mappingsPromise = () => {
        return new Promise((resolve, reject) => {
            dispatch(
                customAction(
                    getMappingsAction,
                    undefined,
                    (data: IParameterRest[]) => {
                        const form = data.map((el) => ({
                            source: { name: el.source },
                            target: { name: el.target },
                        }));
                        callbackForms.setInitialBlankForm(form);
                        callbackForms.setBlankForm(form);
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                )
            );
        });
    };
    const latestParams: ObjectLatestParamsRequest = {
        type: 'registers.gps_prioritized_device',
    };

    const mappingsPromiseWithFallback = mappingsPromise().catch((error) => {
        const message = errorMessageHandler(error.status)();
        showNotification(false, message);
        return null;
    });

    const promises: Promise<any>[] = [mappingsPromiseWithFallback];

    if (privileges.gpsPrioritizedDeviceManagement) {
        promises.push(
            restGetMonitoredObjectsLatestParam(
                Number(monitoredObjectId),
                latestParams
            )
        );
    }
    return promises;
};
