import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
            cursor: 'pointer',
            display: 'flex',
        },
    })
);
