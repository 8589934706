import React from 'react';

import { OptionsObject } from 'notistack';
import moment from 'moment';

import {
    IGpsPrioritizedDevice,
    ObjectParamsRequest,
    restPostMonitoredObjectsParams,
} from '../../../../../../../services/registers/monitored';
import { customAction } from '../../../../../../../state/_actions';
import { IPreviewAction } from '../../../../../../../state/ui/discovery/types';
import { IParameterMapping } from '../../../../../../../state/ui/forms';
import { responseMessage } from '../../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../../../helpers/TimeFormatter';

export const getPrioritizedDevicePromise = (
    enqueueSnackbar: (message: string, options: OptionsObject) => void,
    monitoredObjectId: string,
    deviceId: number,
    isPrioritizedDevice: boolean
) => {
    const prioritizeDeviceParams: ObjectParamsRequest<IGpsPrioritizedDevice> = {
        '@type': 'registers.gps_prioritized_device',
        from: TimeFormatter.toUTCLocalString(moment()),
        params: { deviceId: isPrioritizedDevice ? deviceId : null },
    };

    const prioritedDeviceSavePromise = restPostMonitoredObjectsParams(
        Number(monitoredObjectId),
        prioritizeDeviceParams
    )
        .then(() => {
            enqueueSnackbar(
                TranslationHelper.translate('Priority device updated'),
                { variant: 'success' }
            );
        })
        .catch((error) => {
            const message = responseMessage(error.status);
            enqueueSnackbar(
                `${TranslationHelper.translate(
                    'Priority device update error'
                )}: ${TranslationHelper.translate(message.text)}`,
                { variant: message.type }
            );
        });

    return prioritedDeviceSavePromise;
};

export const getMappingsPromise = (
    enqueueSnackbar: (message: string, options: OptionsObject) => void,
    dispatch: React.Dispatch<any>,
    action: IPreviewAction,
    blankForm: IParameterMapping[]
) => {
    const data = blankForm
        .filter((el) => el.target && el.source)
        .map((el) => ({
            source: el.source?.name,
            target: el.target?.name,
        }));
    const mappingsSavePromise = () => {
        return new Promise((resolve, reject) => {
            dispatch(
                customAction(
                    action,
                    data,
                    () => {
                        enqueueSnackbar(
                            TranslationHelper.translate(
                                'Mappings saved successfully'
                            ),
                            { variant: 'success' }
                        );
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                )
            );
        });
    };

    const mappingsPromiseWithFallback = mappingsSavePromise().catch((error) => {
        const message = responseMessage(error.status);
        enqueueSnackbar(
            `${TranslationHelper.translate(
                'Mappings save failed'
            )}: ${TranslationHelper.translate(message.text)}`,
            { variant: message.type }
        );
    });
    return mappingsPromiseWithFallback;
};
