import React, { ComponentType, useEffect, useState } from 'react';

import { compose } from 'redux';

import { default as List } from '@material-ui/core/List';

import { TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { IReport } from '../../../../state/types';
import {
    TPreviewPane,
    activateChart,
    activateGrid,
    handleExpandReportUpdate,
} from '../../../../state/ui/discovery/snapshotting';

import { makeSourceSetId } from '../../../../services/discovery/_shared/utils';
import { makeSourceSetUrl } from '../../../../services/discovery/_shared/urlMakers';

import { useAppDispatch } from '../../../../hooks';

import ReportList from './ReportList';
import DeletionDialog from '../../../../components/dialogs/DeletionDialog';
import { customAction } from '../../../../state/_actions';
import {
    useCreatorLevel,
    useExtendedReports,
    useTimeLocked,
} from '../../selectors/index.hooks';

interface IOwnProps {
    expanded?: boolean;
    report: IReport;
    from?: string;
    to?: string;
    preview?: TPreviewPane;
    afterItemDelete?: () => void;
}

type TProps = IOwnProps & TConnectableProps & TThemableProps;

const Report: React.FC<TProps> = ({
    classes,
    preview,
    report,
    afterItemDelete,
}: TProps) => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState<string | null>(null);
    const [deleteMessage, setDeleteMessage] = useState<string | undefined>(
        undefined
    );
    const expandedReports = useExtendedReports();
    const timeLocked = useTimeLocked();
    const creatorLevel = useCreatorLevel();

    let hasDeleteAction;

    if (report._meta?.actions) {
        hasDeleteAction = report._meta.actions.delete !== undefined;
    }

    useEffect(() => {
        if (expandedReports[report.label] !== undefined) {
            setOpen(!expandedReports[report.label]);
        } else {
            setOpen((report._meta && report._meta.expanded) || false);
        }
    }, []);

    const handleOpenChartClick = () => {
        const action = report?._meta?.actions?.chart;

        if (action) {
            dispatch(
                activateChart(
                    makeSourceSetId(action.params)!,
                    action,
                    {
                        type: 'source-set-chart',
                        level: creatorLevel + 1,
                    },
                    timeLocked
                )
            );
        }
    };

    const handleOpenGridChartClick = () => {
        const action = report?._meta?.actions?.sourceSet;
        if (action) {
            dispatch(
                activateGrid(
                    makeSourceSetId(action.params) ||
                        makeSourceSetUrl(action).toString(),
                    {
                        type: 'source-set-grid-chart',
                        level: creatorLevel + 1,
                    },
                    preview ? preview : null,
                    false,
                    action,
                    timeLocked
                )
            );
        }
    };

    const handleClick = () => {
        dispatch(handleExpandReportUpdate(report.label));
        setOpen(!open);
    };

    const openDeletionDialog = (id: string, message?: string) => {
        setIsDeletionDialogOpen(true);
        setIdToDelete(id);
        setDeleteMessage(message);
    };

    const closeDeletionDialog = () => {
        setIsDeletionDialogOpen(false);
        setIdToDelete(null);
    };

    const handleDeleteAction = () => {
        const deleteAction = report?._meta?.actions?.delete;

        if (!deleteAction) {
            return;
        }
        const action = {
            ...deleteAction,
            params: { ...deleteAction.params, id: idToDelete || '' },
        };

        dispatch(customAction(action, undefined, afterItemDelete));
    };
    return (
        <List component="nav" disablePadding={true} className={classes.list}>
            <ReportList
                open={open}
                handleClick={handleClick}
                classes={classes}
                report={report}
                handleOpenChartClick={handleOpenChartClick}
                handleOpenGridChartClick={handleOpenGridChartClick}
                openDeletionDialog={openDeletionDialog}
            />

            {hasDeleteAction && (
                <DeletionDialog
                    isOpen={isDeletionDialogOpen}
                    close={closeDeletionDialog}
                    confirm={() => {
                        handleDeleteAction();
                        closeDeletionDialog();
                    }}
                    title="Removal confirmation"
                    message={deleteMessage || 'Are you sure?'}
                />
            )}
        </List>
    );
};

export default compose(Themable)(Report) as ComponentType<IOwnProps>;
