import TimeFormatter from '../../../../helpers/TimeFormatter';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { IBinaryValue, IReportEntity } from '../../../../state/types';
import { ICustomFormatting, IDate } from './types';

const valueFormatters: {
    [key: string]: (
        params: any
    ) => string | number | boolean | IBinaryValue | undefined;
} = {
    date: (params: IDate) =>
        !params.value
            ? ''
            : TimeFormatter.dateToString(params.value, params.format),
    binary: (params: IReportEntity) =>
        (params.value && (params.value as IBinaryValue).name) || '',
    number: (params: IReportEntity) => {
        if (params.formatting) {
            if (params.formatting.decimalPlaces) {
                return (params.value as number).toFixed(
                    params.formatting.decimalPlaces
                );
            }
            if (params.formatting.asDuration) {
                return params.value !== undefined &&
                    params.value !== null &&
                    (params.value as number) >= 0
                    ? TimeFormatter.formatDuration(
                          params.value as number,
                          'minutes',
                          'hh:mm'
                      )
                    : undefined;
            }
        }
        return params.value;
    },
    customFormatting: (params: ICustomFormatting) => {
        if (params.type === 'date' && params.formatting.pattern === 'short') {
            return params.value
                ? TimeFormatter.dateToString(
                      params.value as string,
                      params.dateFormat
                  )
                : '';
        }
        return params.value;
    },
    boolean: (params: IReportEntity) =>
        params.value !== null
            ? params.value
                ? TranslationHelper.translate('true')
                : TranslationHelper.translate('false')
            : '',
    duration: (params: IReportEntity) =>
        params.value !== undefined
            ? TimeFormatter.formatDuration(params.value as number)
            : '',
};
export default valueFormatters;
