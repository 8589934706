import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import ExploreIcon from '@mui/icons-material/Explore';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { ICoordinates } from '../../../state/types';
import { useStyles } from './Themable.hooks';

interface IOwnProps {
    coordinates?: ICoordinates;
}

const GoogleMapsButton = ({ coordinates }: IOwnProps) => {
    const classes = useStyles();

    const generateUrl = (coordinates: ICoordinates) =>
        `http://maps.google.com?q=${coordinates.y},${coordinates.x}`;

    if (!coordinates) {
        return null;
    }

    return (
        <Tooltip title={TranslationHelper.translate('See on Google Maps')}>
            <IconButton
                className={classes.contrastText}
                aria-label="See on google maps"
                href={generateUrl(coordinates)}
                target={'_blank'}
                rel={'noreferrer'}
            >
                <ExploreIcon />
            </IconButton>
        </Tooltip>
    );
};

export default GoogleMapsButton;
