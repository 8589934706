import { FieldProps } from 'formik';
import React, { useRef } from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { TextField } from '@mui/material';
import { useStyles } from './Themable.hooks';
import Translator from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    displayFormat?: string;
    withDateFormatter?: (date: MaterialUiPickersDate) => string;
    showClearIcon?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    label?: string;
}

const MobileDateTimeFieldWrapper: React.FunctionComponent<
    FieldProps & IOwnProps
> = ({
    field: { name, value },
    form: { setFieldValue },
    displayFormat,
    withDateFormatter,
    showClearIcon,
    disabled,
    ...props
}) => {
    const classes = useStyles();
    const dateTranslations = Translator.makeDateTimeTranslations();
    const ref: React.Ref<HTMLInputElement> = useRef(null);
    const [openPicker, setOpenPicker] = React.useState(false);

    return (
        <MobileDateTimePicker
            open={openPicker && !disabled}
            label={props?.label}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            localeText={dateTranslations}
            value={value ? moment(value) : null}
            onChange={(date) =>
                setFieldValue(name, date ? date.toISOString() : '')
            }
            onClose={() => {
                setOpenPicker(false);
            }}
            disabled={disabled}
            format={displayFormat}
            slotProps={{
                layout: {
                    slotProps: {
                        layout: {
                            className: classes.dateDialog,
                        },
                    },
                },
                actionBar: {
                    actions: ['cancel', 'accept'],
                },
                textField: {
                    variant: 'standard',
                    error: props?.error,
                    helperText: props?.helperText,
                    className: classes.textField,
                    inputRef: ref,
                    onClick: () => {
                        setOpenPicker((prev) => !prev);
                        if (ref && ref.current !== null) {
                            ref.current.blur();
                        }
                    },
                },
            }}
            slots={{
                textField: (params: any) => {
                    return (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: showClearIcon && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            color="primary"
                                            disabled={disabled}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setFieldValue(name, undefined);
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    );
                },
            }}
        />
    );
};

export default MobileDateTimeFieldWrapper;
