import React, { useEffect, useRef, useState } from 'react';

import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import SquareIcon from '@mui/icons-material/Square';
import { LinearProgress, Paper, useMediaQuery } from '@material-ui/core';

import 'ol/ol.css';

import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { Vector as VectorSource } from 'ol/source';
import { OSM } from 'ol/source';
import olMap from 'ol/Map';
import { unByKey } from 'ol/Observable';
import { EventsKey } from 'ol/events';
import { MapBrowserEvent } from 'ol';
import Draw from 'ol/interaction/Draw';
import { Geometry, Point } from 'ol/geom';

import {
    useTasksSourceSetWithFilter,
    useTasksStatus,
} from '../../../../state/ui/planning/tasks/index.hooks';
import {
    useSelectedRoutes,
    useSelectedRoutesColors,
} from '../../../../state/ui/planning/routes/index.hooks';

import { MAX_ZOOM } from '../../../../constants/map/zoom';
import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';

import { useStyles } from '../../Themable.hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';

import {
    fitMap,
    getControls,
    LARGE_MAP_PADDING,
} from '../../../discovery/components/DiscoveryMap/_utils';
import { makePoint } from '../../../discovery/components/DiscoveryMap/_utils/features';

import ContextMenu from '../../../../components/ContextMenu';
import PaneHeader from '../../../../components/PaneHeader';
import ListContainer from '../../../../components/ListContainer';
import MenuDropdown from '../../../../components/MenuDropdown';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import ListItemContainer from '../../../../components/ListContainer/ListItemContainer';

import {
    handleDrawEnd,
    handleSelectOnClick,
    handleSelectSingle,
} from './utils/handleSelect';
import { getFeatures } from './utils/getFeatures';
import { STYLE } from './utils/style';

interface IOwnProps {
    selectItems: (items: string[]) => void;
    selectedItems: string[];
    source: VectorSource<Geometry> | null;
    setSource: (source: VectorSource<Geometry>) => void;
    map: olMap | null;
    setMap: (map: olMap) => void;
}
const PlanningRoutesMap = ({
    selectItems,
    selectedItems,
    source,
    setSource,
    map,
    setMap,
}: IOwnProps) => {
    const classes = useStyles();

    const mapRef = useRef(null);
    const [clickListener, setClickListener] = useState<EventsKey | EventsKey[]>(
        []
    );
    const [pointsLayer, setPointsLayer] = useState<WebGLPointsLayer<
        VectorSource<Point>
    > | null>(null);

    const [contextOpen, setContextOpen] = useState(false);
    const [position, setPosition] = useState<{ x: number; y: number } | null>(
        null
    );
    const [drawing, setDrawing] = useState(false);

    const isMobile = useMediaQuery(MOBILE);
    const controls = getControls(isMobile);
    const sourceSet = useTasksSourceSetWithFilter();
    const status = useTasksStatus();

    const selectedRoutes = useSelectedRoutes();
    const selectedRoutesColors = useSelectedRoutesColors();

    useEffect(() => {
        //create map
        map = new Map({
            target: mapRef.current as unknown as HTMLDivElement,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            controls,
            view: new View({
                center: makePoint(18.6466, 54.352),
                zoom: 4,
                minZoom: 4,
            }),
        });
        setMap(map);
        return () => {
            map?.setTarget(undefined);
        };
    }, []);

    useEffect(() => {
        //add click listeners
        if (!map || !sourceSet) {
            return;
        }

        unByKey(clickListener);
        const click = map.on('click', (e) => handleMapClick(e, selectedItems));
        map.getViewport().addEventListener('contextmenu', handleRightClick);

        setClickListener(click);
    }, [map, sourceSet, source, selectedItems]);

    useEffect(() => {
        //add drawing interaction - option from context menu (right click)
        if (!source || !map) return;
        const draw = new Draw({
            source: new VectorSource(),
            type: 'Circle',
        });
        if (drawing) {
            map.addInteraction(draw);
            draw.on('drawend', function (e) {
                if (drawing) {
                    handleDrawEnd(e, source, selectItems, setDrawing);
                } else {
                    map?.removeInteraction(draw);
                }
            });
        } else {
            map.removeInteraction(draw);
        }
        return () => {
            // Clean up: remove the interaction if it exists
            if (map && draw) {
                map.removeInteraction(draw);
            }
        };
    }, [source, drawing, contextOpen]);

    useEffect(() => {
        //draw/remove features
        if (
            status !== 'done' ||
            !map ||
            !sourceSet?.entities?.length ||
            !selectedRoutes.length
        ) {
            pointsLayer?.dispose();
            pointsLayer && map?.removeLayer(pointsLayer);
            return;
        }
        const features = getFeatures(sourceSet, selectedRoutesColors);

        const source = new VectorSource({ features });
        setSource(source);

        const layer = new WebGLPointsLayer({
            source: source,
            style: STYLE,
        });
        setPointsLayer(layer);
        map.addLayer(layer);

        const onRenderComplete = () => {
            const extent = layer.getSource()?.getExtent();
            if (selectedItems?.length === 0) {
                extent &&
                    map &&
                    fitMap(map, extent, LARGE_MAP_PADDING, MAX_ZOOM);
            }
            map?.un('rendercomplete', onRenderComplete);
        };

        map.on('rendercomplete', onRenderComplete);

        return () => {
            pointsLayer?.dispose();
            pointsLayer && map?.removeLayer(pointsLayer);
            map?.un('rendercomplete', onRenderComplete);
        };
    }, [map, sourceSet, status, selectedRoutes]);

    useEffect(() => {
        //dispose map after change in selectedRoutes - to properly refresh features
        if (map && pointsLayer) {
            pointsLayer.dispose();
            map.removeLayer(pointsLayer);
            selectItems([]);
        }
    }, [selectedRoutes]);

    const handleRightClick = (evt: MouseEvent) => {
        evt.preventDefault();

        if (contextOpen) {
            return;
        }

        setPosition({ x: Number(evt.clientX), y: Number(evt.clientY) });
    };

    const handleMapClick = (
        event: MapBrowserEvent<MouseEvent>,
        items: string[]
    ) => {
        if (!map) {
            return;
        }
        if (event.originalEvent.ctrlKey) {
            // Select with ctrl to select another item, without unselecting
            handleSelectOnClick(map, event.pixel, selectItems, items);
        } else {
            // Select without ctrl to unselect all other items
            handleSelectSingle(map, event.pixel, source, selectItems);
        }
    };
    const renderRightCustomControls = () => {
        if (!selectedRoutes.length) {
            return <></>;
        }
        return (
            <>
                <MenuDropdown
                    iconComponent={(callback) => (
                        <IconButtonWithTooltip
                            title={TranslationHelper.translate('Legend')}
                            onClick={(e) => callback(e)}
                        >
                            <LegendToggleIcon />
                        </IconButtonWithTooltip>
                    )}
                >
                    <ListContainer>
                        {selectedRoutes.map((route, i) => {
                            return (
                                <ListItemContainer key={i}>
                                    <SquareIcon
                                        sx={{
                                            color: selectedRoutesColors[
                                                route.id
                                            ],
                                        }}
                                    />
                                    {route.name}
                                </ListItemContainer>
                            );
                        })}
                    </ListContainer>
                </MenuDropdown>
            </>
        );
    };

    return (
        <Paper className={classes.container}>
            <div className={classes.mapPane}>
                <PaneHeader
                    title={TranslationHelper.translate('Tasks map')}
                    renderRightCustomControls={renderRightCustomControls}
                />
            </div>
            {status === 'loading' && <LinearProgress />}
            <div className={classes.smallMap}>
                <div
                    id="tasksmap"
                    ref={mapRef}
                    style={{ width: '100%' }}
                    className={classes.map}
                ></div>
            </div>
            <ContextMenu
                position={position}
                close={() => setContextOpen(false)}
                action={() => {
                    setContextOpen(false);
                    setDrawing(true);
                }}
                buttonText="Select tasks"
            />
        </Paper>
    );
};

export default PlanningRoutesMap;
