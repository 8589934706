import React from 'react';

import { useMediaQuery } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Description';
import { default as EditIcon } from '@material-ui/icons/Edit';
import { default as PlusIcon } from '@material-ui/icons/Add';

import { useStyles } from '../../Themable.hooks';

import { IReport, IReportEntity } from '../../../../../../../../state/types';

import {
    ItemAction,
    DeleteItemAction,
    InternalLinkAction,
} from '../../../../../../../../components/ButtonActions/ButtonActions';
import { DESKTOP } from '../../../../../../../../constants/dictionaries/ScreenSizeConst';
import {
    useEntityAction,
    useReportAction,
} from './../../../../../../hooks/useHandleActions';

interface IOwnProps {
    entity: IReportEntity;
    report: IReport;
    handleDelete?: (id: string, message?: string) => void;
}

const ListItemEntityActions = ({ report, entity, handleDelete }: IOwnProps) => {
    const classes = useStyles();

    const {
        edit: editAction,
        delete: deleteAction,
        preview: previewAction,
        add: addAction,
        internalLink: internalLinkAction,
    } = entity.actions ?? {};

    const reportActionTypes = report?._meta?.actions;
    const isDesktop = !!useMediaQuery(DESKTOP);

    const handleEntityAction = useEntityAction(report);
    const handleReportAction = useReportAction(report);

    const editHandler =
        editAction &&
        (reportActionTypes?.edit ? handleReportAction : handleEntityAction);

    const previewHandler =
        previewAction &&
        (reportActionTypes?.preview ? handleReportAction : handleEntityAction);

    const deleteHandler =
        deleteAction && reportActionTypes?.delete && handleDelete;
    const addHandler = addAction
        ? handleEntityAction
        : reportActionTypes?.add && handleReportAction;
    const deleteMeta = deleteHandler && reportActionTypes?.delete?._meta;

    return (
        <>
            {isDesktop && (
                <>
                    {internalLinkAction && (
                        <InternalLinkAction
                            classes={classes}
                            url={internalLinkAction.url}
                            openInNewTab={internalLinkAction.openInNewTab}
                            icon={internalLinkAction._meta.icon}
                        />
                    )}
                    {previewAction && previewHandler && (
                        <ItemAction
                            classes={classes}
                            params={previewAction.params}
                            handler={previewHandler}
                            action="preview"
                            label="Details"
                            id={entity.id}
                        >
                            <DetailsIcon className={classes.iconButtonIcon} />
                        </ItemAction>
                    )}
                    {editAction && editHandler && (
                        <ItemAction
                            classes={classes}
                            params={editAction.params}
                            handler={editHandler}
                            action="edit"
                            label="Edit"
                            id={entity.id}
                        >
                            <EditIcon className={classes.iconButtonIcon} />
                        </ItemAction>
                    )}
                    {addAction && addHandler && (
                        <ItemAction
                            classes={classes}
                            params={addAction.params}
                            handler={addHandler}
                            action="add"
                            label="Add"
                            id={entity.id}
                        >
                            <PlusIcon className={classes.iconButtonIcon} />
                        </ItemAction>
                    )}
                    {deleteAction && deleteHandler && (
                        <DeleteItemAction
                            classes={classes}
                            params={deleteAction.params}
                            handler={deleteHandler}
                            deleteMeta={deleteMeta}
                        >
                            <DeleteIcon className={classes.iconButtonIcon} />
                        </DeleteItemAction>
                    )}
                </>
            )}
        </>
    );
};

export default ListItemEntityActions;
