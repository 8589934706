import { createStyles, Theme, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            fontSize: theme.typography.caption.fontSize,
            justifyContent: 'space-between',
            borderTop: '1px solid ' + theme.palette.divider,
            color: theme.palette.primary.dark,
        },
        secondaryAction: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '60%',
            wordWrap: 'break-word',
            textAlign: 'right',
        },
        itemLabel: {
            fontFamily: 'OpenSans-Regular',
            fontSize: theme.typography.caption.fontSize,
            paddingRight: theme.spacing(),
            fontWeight: 'bold',
            wordWrap: 'break-word',
            width: '40%',
            display: 'contents',
        },
        iconButton: {
            height: 30,
            width: 30,
            padding: 0,
        },
        iconButtonIcon: {
            height: 25,
            width: 25,
        },
        itemValue: {
            fontSize: theme.typography.caption.fontSize,
            width: '100%',
            textAlign: 'right',
        },
        attachment: {
            fontSize: theme.typography.caption.fontSize,
            display: 'flex',
            // width: '45%',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        downloadLink: {
            display: 'inline-block',
            maxWidth: 150,
            cursor: 'pointer',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            justifySelf: 'flex-start',
        },
        tooltip: {
            '&$open': {
                opacity: 1,
                background: 'black',
            },
        },
        open: {
            // skip
        },
        tooltipContentWrapper: {
            position: 'relative',
        },
        tooltipProgressWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        circularProgress: {
            color: 'white',
        },
        tooltipImage: {
            width: 300,
            height: 300,
            position: 'relative',
            zIndex: 10,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
        },
        validImage: {
            width: '100%',
            minHeight: 218.25,
            height: '100%',
            position: 'relative',
            zIndex: 10,
        },
        invalidImage: {
            width: '100%',
            height: 218.25,
            position: 'relative',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.default,
        },
        photoContainer: {
            width: '100%',
            position: 'relative',
            '&:hover $photoOverlay > button:first-child': {
                display: 'block',
            },
        },
        photoOverlay: {
            '& > button': {
                display: 'none',
            },
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(2),
            cursor: 'pointer',
            display: 'flex',
        },
    })
);
