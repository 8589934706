import React from 'react';

import { Hidden } from '@material-ui/core';

import { IReportEntity } from '../../../../../../../../state/types';
import { ISourceSetAction } from '../../../../../../../../state/ui/discovery/types';
import { usePreviewAction } from '../../../../../../selectors/index.hooks';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';
import useActivateSourceSetGrid from '../../../../../../hooks/useActivateSourceSetGrid';

import { useStyles } from '../../Themable.hooks';

import valueFormatters from '../../../../../..//components/_utils/valueFormatters';
import {
    DownloadAction,
    PreviewAction,
    SourceSetAction,
    ExternalLinkAction,
} from '../../../../../../../../components/ButtonActions/ButtonActions';

interface IOwnProps {
    entity: IReportEntity;
}

interface ISourceSetEntityProps {
    action?: ISourceSetAction;
}

const formatEntityValueToString = (entity: IReportEntity): string => {
    if (entity.value) {
        return entity.translateValue
            ? TranslationHelper.translate(
                  entity.value.toString(),
                  entity.translateValue
              )
            : entity.value.toString();
    } else {
        return '';
    }
};

const SourceSetEntity = ({ action }: ISourceSetEntityProps) => {
    const classes = useStyles();
    const panePreviewAction = usePreviewAction();
    const handleOpenSourceSetClick = useActivateSourceSetGrid();
    const handleOnClick = () => handleOpenSourceSetClick(action);

    return (
        <>
            {action && !panePreviewAction?._webx?.changeSourceSetDisabled && (
                <Hidden smDown={true}>
                    <SourceSetAction
                        classes={classes}
                        handleOnClick={handleOnClick}
                    />
                </Hidden>
            )}
        </>
    );
};

export const ListItemEntityValue = ({ entity }: IOwnProps) => {
    const classes = useStyles();

    const {
        preview: previewAction,
        download: downloadAction,
        externalLink: externalLinkAction,
        sourceSet: sourceSetAction,
    } = entity.actions ?? {};

    const entityName =
        typeof entity.value === 'object' ? entity.value.name : '';

    const linkEntity =
        String(entity.value).startsWith('http') &&
        entity.type !== 'externalLink';

    const sourceSetEntity =
        (Array.isArray(entity.value) || typeof entity.value !== 'object') &&
        !externalLinkAction &&
        !linkEntity;

    if (!entity.label) return <></>;

    if (sourceSetEntity) {
        return (
            <span className={classes.itemValue}>
                {(valueFormatters[entity.type] || formatEntityValueToString)(
                    entity
                )}
                <SourceSetEntity action={sourceSetAction} />
            </span>
        );
    }

    return (
        <span className={classes.attachment}>
            {downloadAction && (
                <DownloadAction
                    classes={classes}
                    api={downloadAction.api}
                    name={entityName}
                    isMovie={(entity.value as { isMovie: boolean }).isMovie}
                />
            )}
            {previewAction && (
                <Hidden smDown={true}>
                    <PreviewAction classes={classes} api={previewAction.api} />
                </Hidden>
            )}
            {externalLinkAction && (
                <ExternalLinkAction
                    classes={classes}
                    url={externalLinkAction.url}
                />
            )}

            <SourceSetEntity action={sourceSetAction} />
            {linkEntity && (
                <ExternalLinkAction
                    classes={classes}
                    url={entity.value.toString()}
                    withCopy
                />
            )}
        </span>
    );
};
