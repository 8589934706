import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    const padding = theme.spacing();

    return createStyles({
        layout: {
            flexGrow: 1,
            display: 'grid',
            gridTemplateColumns: 'auto 400px',
            gridTemplateRows: '1fr 1fr',
            gridColumnGap: padding * 3 + 'px',
            gridRowGap: padding * 3 + 'px',
            boxSizing: 'border-box',
            margin: padding * 3,
        },
        layoutMap: {
            gridTemplateAreas: `
                "map map"
                "map map"
            `,
        },
        layoutMapGridPreview: {
            gridTemplateAreas: `
                "map preview"
                "grid preview"
            `,
        },
        layoutMapPreview: {
            gridTemplateAreas: `
                "map preview"
                "map preview"
            `,
        },
        layoutMapGrid: {
            gridTemplateAreas: `
                "map map"
                "grid grid"
            `,
        },
        layoutFullGrid: {
            gridTemplateAreas: `
                "grid grid"
                "grid grid"
            `,
        },

        mobileNav: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            right: 0,
            height: '56px',
        },
        mobilePreviewPane: {
            [theme.breakpoints.down('md')]: {
                top: -56,
            },
            [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
                top: -48,
            },
            [theme.breakpoints.up('sm')]: {
                top: -64,
            },
        },
    });
});
