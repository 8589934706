import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { IParameterMapping } from '../../../../../../state/ui/forms';
import { resetLevel } from '../../../../../../state/_actions';

import {
    fetchSourceParamsDefinitions,
    fetchTargetParamsDefinitions,
} from '../../../../../../state/app/mappings';
import { usePrivileges } from '../../../../../../state/auth/index.hooks';

import { responseMessage } from '../../../../../../helpers/responseMessage';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';
import { useAppDispatch } from '../../../../../../hooks';

import { useCreatorLevel, usePane } from '../../../../selectors/index.hooks';

import EditDeviceForm from './components/EditDeviceForm/EditDeviceForm';
import CrudPane from '../../../../../../components/CrudPane';
import PrioritizedDeviceCheckbox from './components/PrioritizeDeviceCheckbox/PrioritizeDeviceCheckbox';
import { initialDataPromises } from './helpers/initialDataPromises';
import {
    getMappingsPromise,
    getPrioritizedDevicePromise,
} from './helpers/prepareSubmitDataPromises';

const EditDevice = () => {
    const privileges = usePrivileges();
    const pane = usePane();
    const creatorLevel = useCreatorLevel();

    const [isPrioritizedDevice, setIsPrioritizedDevice] =
        useState<boolean>(false);
    const [initialPrioritizedDevice, setInitialPrioritizedDevice] =
        useState<boolean>(false);
    const [mappingsLoaded, setMappingsLoaded] = useState<boolean>(false);
    const [blankForm, setBlankForm] = useState<IParameterMapping[]>([]);
    const [initialBlankForm, setInitialBlankForm] = useState<
        IParameterMapping[]
    >([]);
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const monitoredObjectId = pane?.elementId;
    const deviceId = pane?.previewAction?.params?.deviceId;

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    useEffect(() => {
        dispatch(
            fetchSourceParamsDefinitions((error) => {
                const message = responseMessage(error.status);
                enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Parameters definitions download failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
            })
        );

        dispatch(
            fetchTargetParamsDefinitions((error) => {
                const message = responseMessage(error.status);
                enqueueSnackbar(
                    `${TranslationHelper.translate(
                        'Parameters definitions download failed'
                    )}: ${TranslationHelper.translate(message.text)}`,
                    { variant: message.type }
                );
            })
        );
        handleFetchInitialFormData();
    }, []);

    const handleFetchInitialFormData = async () => {
        const action = pane && pane.previewAction;

        if (action) {
            const promises = initialDataPromises(
                dispatch,
                { setBlankForm, setInitialBlankForm },
                showNotification,
                privileges,
                monitoredObjectId,
                action
            );

            try {
                const results = await Promise.allSettled(promises);
                if (results[1]?.status === 'fulfilled') {
                    const deviceResult = results[1]?.value;
                    if (
                        deviceResult?.['registers.gps_prioritized_device']
                            ?.params?.deviceId === deviceId
                    ) {
                        setInitialPrioritizedDevice(true);
                        setIsPrioritizedDevice(true);
                    }
                }
            } catch (error: any) {
                const message = errorMessageHandler(error.status)();
                showNotification(false, message);
            } finally {
                setMappingsLoaded(true);
            }
        }
    };

    const handleSubmit = () => {
        const action = pane && pane.previewAction;

        if (!action) {
            return;
        }
        const promises: Promise<any>[] = [];
        if (initialBlankForm != blankForm) {
            promises.push(
                getMappingsPromise(enqueueSnackbar, dispatch, action, blankForm)
            );
        }
        if (
            isPrioritizedDevice != initialPrioritizedDevice &&
            privileges.gpsPrioritizedDeviceManagement &&
            monitoredObjectId &&
            deviceId
        ) {
            promises.push(
                getPrioritizedDevicePromise(
                    enqueueSnackbar,
                    monitoredObjectId,
                    deviceId,
                    isPrioritizedDevice
                )
            );
        }

        Promise.allSettled(promises).finally(() => handleCloseClick());
    };

    const handleCloseClick = () => {
        dispatch(resetLevel(creatorLevel));
    };

    return (
        <CrudPane
            titles={{ add: 'Edit' }}
            loaded={
                pane !== null &&
                (pane.mode === 'add' ||
                    pane.mode === 'edit' ||
                    pane.mode === 'preview')
            }
            initialValues={blankForm}
            onClose={handleCloseClick}
            mode="add"
            changeModeHandler={handleCloseClick}
            onAdd={handleSubmit}
            renderContent={() => {
                return (
                    <EditDeviceForm
                        blankForm={blankForm}
                        setBlankForm={setBlankForm}
                        mappingsLoaded={mappingsLoaded}
                        priorityDeviceCheckbox={
                            <PrioritizedDeviceCheckbox
                                isPrioritizedDevice={isPrioritizedDevice}
                                setIsPrioritizedDevice={setIsPrioritizedDevice}
                            />
                        }
                    />
                );
            }}
            preventSubmitOnEnterKey={true}
        />
    );
};

export default EditDevice;
