import React, { Fragment } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { default as IconButton } from '@material-ui/core/IconButton';
import { default as ChartIcon } from '@material-ui/icons/InsertChart';
import { default as MultiLineChartIcon } from '@material-ui/icons/MultilineChart';
import { default as GridIcon } from '@material-ui/icons/GridOn';

import { IReport } from '../../../../state/types';
import { DESKTOP } from '../../../../constants/dictionaries/ScreenSizeConst';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import useActivateSourceSetGrid from '../../hooks/useActivateSourceSetGrid';

interface IOwnProps {
    report: IReport;
    handleOpenChartClick: () => void;
    handleOpenGridChartClick: () => void;
}

const ReportDetailsIcons = ({
    report,
    handleOpenChartClick,
    handleOpenGridChartClick,
}: IOwnProps) => {
    const matches = useMediaQuery(DESKTOP);

    const chartAction = report?._meta?.actions?.chart;
    const sourceSetAction = report?._meta?.actions?.sourceSet;

    const handleOpenSourceSetClick = useActivateSourceSetGrid();

    return (
        <Fragment>
            {matches && chartAction && (
                <IconButtonWithTooltip
                    defaultColor
                    title={TranslationHelper.translate(chartAction.label || '')}
                    onClick={handleOpenChartClick}
                >
                    <ChartIcon />
                </IconButtonWithTooltip>
            )}
            {matches &&
                sourceSetAction &&
                sourceSetAction?.params.reportId !== 'tasks' && (
                    <IconButton onClick={handleOpenGridChartClick}>
                        <MultiLineChartIcon />
                    </IconButton>
                )}
            {sourceSetAction && (
                <IconButton
                    onClick={() => handleOpenSourceSetClick(sourceSetAction)}
                >
                    <GridIcon />
                </IconButton>
            )}
        </Fragment>
    );
};

export default ReportDetailsIcons;
