import { FieldProps } from 'formik';
import React from 'react';

import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

interface IOwnProps {
    displayFormat?: string;
    withDateFormatter?: (date: MaterialUiPickersDate) => string;
    showClearIcon?: boolean;
    disabled?: boolean;
}

const DateTimeFieldWrapper: React.FunctionComponent<FieldProps & IOwnProps> = ({
    field: { name, value },
    form: { setFieldValue },
    displayFormat,
    withDateFormatter,
    showClearIcon,
    disabled,
    ...props
}) => (
    <DateTimePicker
        value={(value && moment(value)) || moment()} // ! or always send date from parent component
        onChange={(val: MaterialUiPickersDate) => {
            setFieldValue(
                name,
                withDateFormatter ? withDateFormatter(val) : val
            );
        }}
        disabled={disabled}
        format={displayFormat}
        variant="inline"
        ampm={false}
        autoOk={true}
        InputProps={{
            endAdornment: showClearIcon && (
                <InputAdornment position="end">
                    <IconButton
                        color="primary"
                        disabled={disabled}
                        onClick={(event) => {
                            event.stopPropagation();
                            setFieldValue(name, undefined);
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
        {...props}
    />
);

export default DateTimeFieldWrapper;
