import classNames from 'classnames';
import { useStyles } from '../Themable.hooks';
import {
    useIsCalibrationDataChartPaneVisible,
    useIsChartPaneVisible,
    useIsDataGridPaneVisible,
    useIsDetectionChartPaneVisible,
    useIsGridFullScreenOrAnalytics,
    usePane,
} from '../selectors/index.hooks';

const useDiscoveryLayout = () => {
    const classes = useStyles();
    const pane = usePane();

    const isDataGridPaneVisible = useIsDataGridPaneVisible();
    const isChartPaneVisible = useIsChartPaneVisible();
    const isCalibrationChartPaneVisible =
        useIsCalibrationDataChartPaneVisible();
    const isDetectionChartPaneVisible = useIsDetectionChartPaneVisible();

    const isGridFullscreen = useIsGridFullScreenOrAnalytics();
    const isPreviewPaneVisible = pane !== null;
    const isBottomPaneVisible =
        isDataGridPaneVisible ||
        isChartPaneVisible ||
        isCalibrationChartPaneVisible ||
        isDetectionChartPaneVisible;

    const {
        layout,
        layoutMap,
        layoutMapGrid,
        layoutMapGridPreview,
        layoutMapPreview,
        layoutFullGrid,
    } = classes;

    const layoutClassName = classNames({
        [layout]: true,
        [layoutMapGridPreview]: isPreviewPaneVisible && isBottomPaneVisible,
        [layoutMapGrid]: !isPreviewPaneVisible && isBottomPaneVisible,
        [layoutMapPreview]: isPreviewPaneVisible && !isBottomPaneVisible,
        [layoutMap]: !isPreviewPaneVisible && !isBottomPaneVisible,
        [layoutFullGrid]: isBottomPaneVisible && isGridFullscreen,
    });

    return { layoutClassName, isBottomPaneVisible, isPreviewPaneVisible };
};

export default useDiscoveryLayout;
