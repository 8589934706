import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import hasAccess from '../../components/accessControl/hasAccess';
import StateRestorer from '../../components/StateRestorer';
import View from '../../components/View/View';
import PageContent from '../../components/PageContent';

import { Connectable, TConnectableProps } from './Connectable.hoc';

import { isMobile } from '../../helpers/IsMobile';
import TimeFormatter from '../../helpers/TimeFormatter';
import TranslationHelper from '../../helpers/TranslationHelper';

import { areLastStatesEnabled } from '../../constants/AppConfig';

import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import moment from 'moment-timezone';
import { Prompt } from 'react-router-dom';

import {
    withMediaQuery,
    IWithMediaQueryProps,
} from '../../components/WithMediaQuery/WithMediaQuery';

import SearchResults from './components/SearchResults';
import { TDataGridPane } from 'src/app/state/ui/discovery/snapshotting';
import MapPane from './MapPane';
import DesktopGrid from './DekstopGrid';
import MobileGrid from './MobileGrid';

type TProps = TConnectableProps & WithWidthProps & IWithMediaQueryProps;

class DiscoveryPage extends Component<TProps> {
    componentDidMount() {
        if (areLastStatesEnabled()) {
            if (this.props.gridSnapshot?.sourceSetId !== 'lastStates') {
                this.fetchSourceSets();
            } else {
                this.props.fetchLastStates({ differential: true });
            }
        } else {
            this.fetchSourceSets();
        }
    }

    componentDidUpdate(prevProps: TProps) {
        this.fetchSourceSets(prevProps);
    }

    componentWillUnmount() {
        this.props.stopFetchingCollection('lastStates');
        this.props.stopFetchingCollection(this.props.sourceSet?.id || '');
    }

    fetchSourceSets = (prevProps?: TProps) => {
        const {
            isDataGridPaneVisible,
            fetchLastStates,
            fetchLocations,
            fetchRoutes,
            gridSnapshot,
            isStateRestoring,
            notifyRestorerToWait,
            notifyRestorerToContinue,
            fetchSourceSet,
            gridFilters,
        } = this.props;

        const isDifferentSourceSet = prevProps
            ? gridSnapshot?.sourceSetId !==
                  prevProps?.gridSnapshot?.sourceSetId ||
              gridSnapshot?.filters !== prevProps.gridSnapshot?.filters
            : true;

        if (
            gridSnapshot &&
            (isDataGridPaneVisible ||
                gridSnapshot.sourceSetId === 'lastStates') &&
            isDifferentSourceSet
        ) {
            const { sourceSetDates, sourceSetAction } = gridSnapshot;

            const currentDay = TimeFormatter.dateToShortDateString(moment());
            const from = sourceSetDates?.from || currentDay;
            const to = sourceSetDates?.to || currentDay;
            switch (gridSnapshot.sourceSetId) {
                case 'lastStates':
                    fetchLastStates({ ...gridFilters, differential: true });

                    break;
                case 'locations':
                    fetchLocations();
                    break;
                case 'routes':
                    fetchRoutes({ from, to });
                    this.handleSourceSetDates(from, to);
                    break;
                case 'tasks': {
                    this.handleFetchTasks(gridSnapshot, from, to);
                    break;
                }
                default:
                    if (sourceSetAction !== null) {
                        sourceSetAction.params = {
                            ...sourceSetAction.params,
                            ...gridFilters,
                        };
                        if (isStateRestoring) {
                            notifyRestorerToWait();
                        }

                        (
                            fetchSourceSet(
                                sourceSetAction
                            ) as unknown as Promise<any>
                        ).then(() => {
                            if (isStateRestoring) {
                                notifyRestorerToContinue();
                            }
                        });
                    }
            }
        }
    };

    handleFetchTasks = (snapshot: TDataGridPane, from: string, to: string) => {
        const {
            fetchTasks,
            setNotAssignedFilter,
            setNotPlannedFilter,
            setIncludeNotPlanned,
        } = this.props;

        const { onlyNotAssigned, onlyNotPlanned } = snapshot;

        const fetchTasksParams = () => {
            return onlyNotPlanned
                ? {
                      onlyNotAssigned,
                      includeNotPlanned: true,
                  }
                : {
                      from,
                      to,
                      onlyNotAssigned,
                      includeNotPlanned: true,
                  };
        };

        fetchTasks(fetchTasksParams());
        this.handleSourceSetDates(from, to);
        setNotAssignedFilter(snapshot.sourceSetId, !!onlyNotAssigned);
        setNotPlannedFilter(snapshot.sourceSetId, !!onlyNotPlanned);

        setIncludeNotPlanned(snapshot.sourceSetId, true);
    };

    handleSourceSetDates = (from: string, to: string) => {
        const { gridSnapshot, setSourceSetDates } = this.props;
        const shortFrom = TimeFormatter.dateToShortDateString(from);
        const shortTo = TimeFormatter.dateToShortDateString(to);
        gridSnapshot &&
            setSourceSetDates(gridSnapshot.sourceSetId, shortFrom, shortTo);
    };

    isMobile = () => {
        const { width, mediaQuery, isDataGridFullscreen } = this.props;
        return !!(!mediaQuery && isMobile(width) && !isDataGridFullscreen);
    };

    getPromptMessage = (location: any, action?: string) => {
        return action === 'REPLACE' || (action === 'PUSH' && this.isMobile())
            ? true
            : TranslationHelper.translate(
                  'Unsaved data will be lost. Do you want to continue?'
              );
    };

    render() {
        const {
            isStateRestoring,
            nextSnapshot,
            shouldRestorerWait,
            areSearchResultsVisible,
            editMode,
        } = this.props;

        // TODO This component renders too often (every time the lastState source set is being fetched on autor refresh)
        // window.console.log('GRID:', layoutClassName)
        return (
            <View
                title={TranslationHelper.translate('Discovery')}
                noPadding={true}
                noColumn={true}
            >
                <PageContent>
                    <>
                        <StateRestorer
                            isStateRestoring={isStateRestoring}
                            shouldRestorerWait={shouldRestorerWait}
                            restorerHandler={nextSnapshot}
                        >
                            {this.isMobile() && areSearchResultsVisible && (
                                <SearchResults isMobile={true} />
                            )}

                            <MapPane isMobile={this.isMobile()} />
                            <DesktopGrid isMobile={this.isMobile()} />
                            <MobileGrid isMobile={this.isMobile()} />
                        </StateRestorer>

                        <Prompt
                            when={editMode}
                            message={this.getPromptMessage}
                        />
                    </>
                </PageContent>
            </View>
        );
    }
}

export default compose(
    Connectable,
    hasAccess((privileges) => privileges.discoveryContext),
    withWidth(),
    withMediaQuery('print')
)(DiscoveryPage) as ComponentType<{}>;
