import { FieldProps } from 'formik';
import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';
import { useStyles } from './Themable.hooks';
import Translator from '../../../../helpers/TranslationHelper';

interface IOwnProps {
    displayFormat?: string;
    withDateFormatter?: (date: MaterialUiPickersDate) => string;
    showClearIcon?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    label?: string;
    required?: boolean;
}

const MobileDateFieldWrapper: React.FunctionComponent<FieldProps & IOwnProps> =
    ({
        field: { name, value },
        form: { setFieldValue },
        displayFormat,
        withDateFormatter,
        showClearIcon,
        disabled,
        ...props
    }) => {
        const classes = useStyles();
        const dateTranslations = Translator.makeDateTimeTranslations();

        return (
            <MobileDatePicker
                label={props?.label}
                views={['year', 'month', 'day']}
                localeText={dateTranslations}
                value={value ? moment(value) : null}
                onChange={(date) =>
                    setFieldValue(name, date ? date.toISOString() : '')
                }
                required={props?.required}
                disabled={disabled}
                format={displayFormat}
                slotProps={{
                    layout: {
                        slotProps: {
                            layout: {
                                className: classes.dateTimeDialog,
                            },
                        },
                    },
                    actionBar: {
                        actions: ['cancel', 'accept'],
                    },
                    textField: {
                        variant: 'standard',
                        error: props?.error,
                        helperText: props?.helperText,
                        className: classes.textField,
                        required: props?.required,
                    },
                }}
                {...props}
            />
        );
    };

export default MobileDateFieldWrapper;
