import React, { ReactChild, useState } from 'react';

import { IconButton, TextField, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete, {
    AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

import { IParameterMapping } from '../../../../../../../../state/ui/forms';
import { IDefinitionItem } from '../../../../../../../../state/app/mappings';

import TranslationHelper from '../../../../../../../../helpers/TranslationHelper';

import { useStyles } from '../../Themable.hooks';

import MappingsList from '../MappingsList';
import LoadingSpinner from '../../../../../../../../components/loadingSpinner/LoadingSpinner';
import {
    useSourceParamsDefs,
    useTargetParamsDefs,
} from '../../../../../../../../pages/discovery/selectors/mappings/index.hooks';

interface IValidation {
    source: {
        error: boolean;
        message: string;
    };
    target: {
        error: boolean;
        message: string;
    };
}

const blankRow: IParameterMapping = {
    target: null,
    source: null,
};

interface IOwnProps {
    blankForm: IParameterMapping[];
    mappingsLoaded: boolean;
    setBlankForm: (form: any) => void;
    priorityDeviceCheckbox: ReactChild;
}
const EditDeviceForm = ({
    blankForm,
    setBlankForm,
    mappingsLoaded,
    priorityDeviceCheckbox,
}: IOwnProps) => {
    const classes = useStyles();
    const targetParamsDefs = useTargetParamsDefs();
    const sourceParamsDefs = useSourceParamsDefs();

    const [newValue, setNewValue] = useState<IParameterMapping>(blankRow);
    const [validation, setValidation] = useState<IValidation>({
        source: {
            error: false,
            message: '',
        },
        target: {
            error: false,
            message: '',
        },
    });

    const handleNewDataChange = (
        field: string,
        value: IDefinitionItem | null
    ) => {
        setNewValue({
            ...newValue,
            target: null,
            [field]: value,
        });
    };

    const addMappingRow = () => {
        const repeated = blankForm.some(
            (el) =>
                el.source?.name === newValue.source?.name &&
                el.target?.name === newValue.target?.name
        );
        if (repeated) {
            setValidation({
                source: {
                    error: true,
                    message: TranslationHelper.translate(
                        'Mapping already exist'
                    ),
                },
                target: {
                    error: true,
                    message: ' ',
                },
            });
            return;
        }
        setValidation({
            source: {
                error: !newValue.source,
                message: !newValue.source
                    ? TranslationHelper.translate('Fill this field')
                    : '',
            },
            target: {
                error: !newValue.target,
                message: !newValue.target
                    ? TranslationHelper.translate('Fill this field')
                    : '',
            },
        });

        if (!newValue.source || !newValue.target) {
            return;
        }

        setNewValue(blankRow);
        setBlankForm([...blankForm, newValue]);
    };

    const removeMapping = (index: number) => {
        setBlankForm(blankForm.filter((el, i) => i !== index));
    };

    const renderSourceInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={TranslationHelper.translate('Source')}
                error={validation.source.error}
                helperText={validation.source.message}
            />
        );
    };

    const renderTargetInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={TranslationHelper.translate('Target')}
                error={validation.target.error}
                helperText={validation.target.message}
            />
        );
    };

    const handleSourceInputChange = (
        event: any,
        value: IDefinitionItem | null
    ) => {
        handleNewDataChange('source', value);
    };
    const handleTargetInputChange = (
        event: any,
        value: IDefinitionItem | null
    ) => {
        handleNewDataChange('target', value);
    };

    const filteredTargetParams = targetParamsDefs.filter(
        (el) =>
            newValue.source &&
            newValue.source.type === el.type &&
            newValue.source.name !== el.name
    );

    const getOptionSelected = (
        option: IDefinitionItem,
        value: IDefinitionItem
    ) => {
        return option.name === value.name;
    };
    const getOptionName = (option: IDefinitionItem) => {
        return option.name;
    };

    const autocompleteParams = {
        className: classes.combobox,
        getOptionLabel: getOptionName,
        getOptionSelected: getOptionSelected,
    };

    return (
        <div className={classes.paneContent}>
            {mappingsLoaded ? (
                <>
                    {priorityDeviceCheckbox}

                    <p>{TranslationHelper.translate('Add new mapping')}</p>

                    <div className={classes.formRow}>
                        <Tooltip title={newValue.source?.name || ''}>
                            <Autocomplete
                                value={newValue.source}
                                onChange={handleSourceInputChange}
                                options={sourceParamsDefs}
                                renderInput={renderSourceInput}
                                {...autocompleteParams}
                            />
                        </Tooltip>
                        <Tooltip title={newValue.target?.name || ''}>
                            <Autocomplete
                                value={newValue.target}
                                onChange={handleTargetInputChange}
                                options={filteredTargetParams}
                                renderInput={renderTargetInput}
                                {...autocompleteParams}
                            />
                        </Tooltip>
                        <IconButton onClick={addMappingRow}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <MappingsList
                        removeMapping={removeMapping}
                        parameters={blankForm}
                    />
                </>
            ) : (
                <LoadingSpinner
                    progressSize="3.5em"
                    top="300"
                    left="0"
                    right="0"
                    bottom="0"
                />
            )}
        </div>
    );
};

export default EditDeviceForm;
