import { useCallback } from 'react';

import { IActionDataParams, IReport } from '../../../state/types';
import {
    TComponentType,
    TSnapshotType,
    activatePreview,
} from '../../../state/ui/discovery/snapshotting';
import { IPreviewAction } from '../../../state/ui/discovery/types';

import { useAppDispatch } from '../../../hooks';

import { useCreatorLevel, usePreviewElementId } from '../selectors/index.hooks';
import { activateDialog } from '../../../state/_actions';
import { TFormTypes } from '../../../state/ui/forms';

const customReportActions: {
    [key: string]: (
        elementCollectionName: string,
        elementId: string | null,
        elementType: string,
        mode: string,
        snapshot: {
            type: TSnapshotType;
            level: number;
            storeResult?: TFormTypes;
        },
        previewAction: IPreviewAction
    ) => void;
} = {
    alertSetting: activateDialog,
};

const handleAction = (
    dispatch: any,
    monitoredId: string | null,
    creatorLevel: number,
    action: IPreviewAction,
    type: 'add' | 'edit' | 'preview' | 'internalLink',
    params?: IActionDataParams
) => {
    if (action) {
        const actionWithParams = {
            ...action,
            params: {
                ...action.params,
                ...params,
            } as IActionDataParams,
        };
        if (
            action.componentName &&
            customReportActions?.[action.componentName]
        ) {
            const activateCustomAction =
                customReportActions[action.componentName];
            dispatch(
                activateCustomAction(
                    actionWithParams?.params?.id || monitoredId || '',
                    monitoredId || action?.params?.id || '',
                    actionWithParams.componentName as string,
                    type,
                    {
                        type:
                            (action.componentName as TComponentType) ||
                            'preview',
                        level: creatorLevel + 1,
                    },
                    actionWithParams
                )
            );
            return;
        }
        dispatch(
            activatePreview(
                actionWithParams?.params?.id || monitoredId || '',
                monitoredId || action?.params?.id || '',
                actionWithParams.componentName as string,
                type,
                {
                    type: (action.componentName as TComponentType) || 'preview',
                    level: creatorLevel + 1,
                },
                actionWithParams
            )
        );
    }
};

export const useEntityAction = (report?: IReport) => {
    const dispatch = useAppDispatch();
    const monitoredId = usePreviewElementId();
    const creatorLevel = useCreatorLevel();

    const handleEntityAction = useCallback(
        (
            type: 'add' | 'edit' | 'preview' | 'internalLink',
            params: IActionDataParams,
            collectionId?: string
        ) => {
            const action = report?.entities?.find(
                (entity) => entity.id === collectionId
            )?.actions?.[type];
            if (action) {
                handleAction(
                    dispatch,
                    monitoredId,
                    creatorLevel,
                    action as IPreviewAction,
                    type,
                    params
                );
            }
        },
        [dispatch, report, creatorLevel, monitoredId]
    );

    return handleEntityAction;
};

export const useReportAction = (report?: IReport) => {
    const dispatch = useAppDispatch();
    const monitoredId = usePreviewElementId();
    const creatorLevel = useCreatorLevel();

    const handleReportAction = useCallback(
        (type: 'add' | 'edit' | 'preview', params?: IActionDataParams) => {
            const action = report?._meta?.actions?.[type];
            if (action) {
                handleAction(
                    dispatch,
                    monitoredId,
                    creatorLevel,
                    action,
                    type,
                    params
                );
            }
        },
        [dispatch, report, creatorLevel, monitoredId]
    );

    return handleReportAction;
};
