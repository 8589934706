import * as yup from 'yup';
import { memoize } from 'lodash';

import TranslationHelper from '../helpers/TranslationHelper';
import { NAME_STRING_MAX_LENGTH, REQUIRED } from '../constants/yup';
import moment, { Moment } from 'moment';

export const createRouteSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        activityCategory: yup
            .number()
            .required(TranslationHelper.translate(REQUIRED))
            .moreThan(-1, TranslationHelper.translate(REQUIRED)),
        plannedOn: yup
            .string()
            .nullable()
            .required(TranslationHelper.translate(REQUIRED)),
        realStart: realStartValidator(),
        realStop: yup.date().nullable(),
    })
);

export const editRouteSchema = memoize(() =>
    yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(TranslationHelper.translate(REQUIRED))
            .max(
                NAME_STRING_MAX_LENGTH,
                TranslationHelper.translate('Value in this field is too long')
            ),
        realStart: realStartValidator(),
        realStop: yup.date().nullable(),
    })
);

const realStartValidator = () => {
    return yup.mixed().when(['realStop'], (realStop: Moment): any => {
        return yup.lazy((realStart: Moment) => {
            if (realStop && !realStart) {
                return yup
                    .date()
                    .nullable()
                    .required(TranslationHelper.translate(REQUIRED));
            }
            if (realStop && !moment(realStart).isBefore(realStop)) {
                return yup
                    .string()
                    .length(
                        1,
                        `${TranslationHelper.translate(
                            'must be earlier than:'
                        )} ${TranslationHelper.translate('Real stop date')}`
                    );
            }
            return yup.date().nullable();
        });
    });
};
