import React from 'react';

import PreviewPane from './components/PreviewPane';

import DataGridPaneMobile from './components/DataGridPaneMobile';
import MobileNavigation from './components/MobileNavigation';

import ErrorBoundary from '../../components/ErrorBoundary';

import Bookmarks from './components/Bookmarks/Bookmarks';

import {
    useCalculatedMobileState,
    useCreatorLevel,
    useIsMobileGridVisible,
} from './selectors/index.hooks';
import { useGridSourceSet } from './selectors/composed/index.hooks';
import { useStyles } from './Themable.hooks';
import { TStyling } from './components/_utils/types';

interface IOwnProps {
    isMobile: boolean;
}

const MobileGrid = ({ isMobile }: IOwnProps) => {
    const mobileUIState = useCalculatedMobileState();
    const isMobileGridVisible = useIsMobileGridVisible();
    const sourceSet = useGridSourceSet();
    const creatorLevel = useCreatorLevel();
    const classes = useStyles();

    const getMobileBottomPaneStyling = (): TStyling => {
        return [
            {
                predicate: () =>
                    !!mobileUIState?.dataGridPane && !!isMobileGridVisible,
                style: {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
        ].find(({ predicate }) => predicate());
    };

    const getMobilePreviewPaneStyling = (): TStyling => {
        return [
            {
                predicate: () => !!mobileUIState?.previewPane,
                style: {
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1100,
                },
            },
        ].find(({ predicate }) => predicate());
    };

    const mobileBottomPaneStyling = getMobileBottomPaneStyling();
    const mobilePreviewPaneStyling = getMobilePreviewPaneStyling();

    if (!isMobile) {
        return null;
    }
    return (
        <>
            <ErrorBoundary>
                <Bookmarks />
            </ErrorBoundary>
            {mobilePreviewPaneStyling && (
                <div
                    className={classes.mobilePreviewPane}
                    style={mobilePreviewPaneStyling.style}
                >
                    <PreviewPane />
                </div>
            )}
            {mobileBottomPaneStyling && (
                <DataGridPaneMobile
                    sourceSet={sourceSet}
                    creatorLevel={creatorLevel}
                />
            )}
            <div className={classes.mobileNav}>
                <MobileNavigation />
            </div>
        </>
    );
};

export default MobileGrid;
