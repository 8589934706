import apiClient from '../common/apiClient';
import {
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
} from '../common/utils';

import UrlConst from '../../constants/UrlConst';
import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';

export const makeMonitoredObjectParamUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.REGISTERS_MONITORED_OBJECTS) + suffix
    );
    return makeUrl(url, params);
};

type TParamType = 'registers.gps_prioritized_device' | 'registers.calibration';

export interface IGpsPrioritizedDevice {
    deviceId: number | null;
}

export interface ObjectParamsRequest<T> {
    '@type': TParamType;
    from?: string;
    params?: T;
}

export interface ObjectLatestParamsRequest {
    type?: TParamType;
}

interface ObjectParamsResponse<T> {
    id: string;
    '@type': TParamType;
    from: string;
    modifiedAt: string;
    objectId: number;
    params: T;
}

type ObjectParamsLatestResponse<T> = {
    [key in TParamType]?: {
        id: string;
        '@type': TParamType;
        from: string;
        modifiedAt: string;
        objectId: number;
        params: T;
    };
};

export const restGetMonitoredObjectsParams = (
    monitoredObjectId: number,
    params?: ObjectParamsRequest<IGpsPrioritizedDevice>
): Promise<ObjectParamsResponse<IGpsPrioritizedDevice>[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeMonitoredObjectParamUrl(`/${monitoredObjectId}/params`, params)
        )
    );

export const restGetMonitoredObjectsLatestParam = (
    monitoredObjectId: number,
    params?: ObjectLatestParamsRequest
): Promise<ObjectParamsLatestResponse<IGpsPrioritizedDevice>> =>
    apiClient.send(
        makeGetRequestSettings(
            makeMonitoredObjectParamUrl(
                `/${monitoredObjectId}/params/latest`,
                params
            )
        )
    );

export const restPostMonitoredObjectsParams = (
    monitoredObjectId: number,
    body: ObjectParamsRequest<IGpsPrioritizedDevice>
): Promise<ObjectParamsResponse<IGpsPrioritizedDevice>> =>
    apiClient.send(
        makePostRequestSettings(
            makeMonitoredObjectParamUrl(`/${monitoredObjectId}/params`, {
                recalculation: false,
            }),
            body
        )
    );

export const restPutMonitoredObjectsParams = (
    monitoredObjectId: number,
    id: string,
    body: ObjectParamsRequest<IGpsPrioritizedDevice>
): Promise<ObjectParamsResponse<IGpsPrioritizedDevice>> =>
    apiClient.send(
        makePutRequestSettings(
            makeMonitoredObjectParamUrl(`/${monitoredObjectId}/params/${id}`),
            body
        )
    );
