import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        listItemHeading: {
            paddingLeft: theme.spacing(2),
            paddingBottom: 0,
            paddingTop: 0,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.grey[200],
        },
        heading: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.primary.dark,
        },
    });
});
