import { useCallback } from 'react';

import { useAppDispatch } from '../../../hooks';
import {
    useCreatorLevel,
    usePane,
    useTimeLocked,
} from '../selectors/index.hooks';
import { ISourceSetAction } from '../../../state/ui/discovery/types';
import { activateGrid } from '../../../state/_actions';
import { makeSourceSetId } from '../../../services/discovery/_shared/utils';
import { makeSourceSetUrl } from '../../../services/discovery/_shared/urlMakers';

const useOpenSourceSet = () => {
    const dispatch = useAppDispatch();
    const timeLocked = useTimeLocked();
    const preview = usePane();
    const creatorLevel = useCreatorLevel();

    const handleOpenSourceSetClick = useCallback(
        (action?: ISourceSetAction) => {
            if (action) {
                dispatch(
                    activateGrid(
                        makeSourceSetId(action.params) ||
                            makeSourceSetUrl(action).toString(),
                        {
                            type: 'source-set-grid',
                            level: creatorLevel + 1,
                        },
                        preview || null,
                        false,
                        action,
                        timeLocked
                    )
                );
            }
        },
        [dispatch, timeLocked, preview, creatorLevel]
    );

    return handleOpenSourceSetClick;
};

export default useOpenSourceSet;
