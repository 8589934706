import { makeReduxDuck } from 'teedux';
import {
    IRestPostPasswordReset,
    IRestPutPassword,
    restPostMigrationResetPassword,
    restPostPasswordReset,
    restPostResendEmail,
    restPostUserLoginMiration,
    restPutPasswordReset,
} from '../../services/auth';
import { makeRequest } from '../app/sync';
import { TRootState } from '../../store';
import { TLoginRequiredActions } from '../login/types';

export interface IPrivileges {
    anonymize: boolean;
    adminPanel: boolean;
    usersPanel: boolean;
    rolesPanel: boolean;
    manageAttributesSettings: boolean;
    setCoordinates: boolean;
    addRole: boolean;
    editRole: boolean;
    deleteRole: boolean;
    setRightUser: boolean;
    addUser: boolean;
    editUser: boolean;
    deleteUser: boolean;
    changeUserPassword: boolean;
    dashboardContext: boolean;
    reportsContext: boolean;
    reportSchedules: boolean;
    registersContext: boolean;
    alertsContext: boolean;
    locations: boolean;
    routes: boolean;
    bookmarks: boolean;
    archive: boolean;
    discoveryContext: boolean;
    tasks: boolean;
    addTask: boolean;
    editTask: boolean;
    deleteTask: boolean;
    objectRecalculation: boolean;
    alertSettings: boolean;
    addAlertSettings: boolean;
    editAlertSettings: boolean;
    registerEvents: boolean;
    manageGroups: boolean;
    RfidImport: boolean;
    eToll: boolean;
    eTollReadOnly: boolean;
    devicesManagement: boolean;
    alertSettingSms: boolean;
    eTollEditRegistrationData: boolean;
    xtrackServiceSystem: boolean;
    contextCustomerServiceCentre: boolean;
    accessInvisibleParams: boolean;
    experimentalFeatures: boolean;
    manageCustomerServiceCentre: boolean;
    anonymizeSwitchVisible: boolean;
    videoProcessingDebug: boolean;
    manageGroupGridViews: boolean;
    managePublicGridViews: boolean;
    ignorePermissionsInServiceCenter: boolean;
    showClientsWithLocationOnly: boolean;
    contextPlanning: boolean;
    addRoute: boolean;
    editRoute: boolean;
    deleteRoute: boolean;
    requireDeviceConfirmation: boolean;
    manageTrustedDevice: boolean;
    gpsPrioritizedDeviceManagement: boolean;
}

interface IState {
    token: string | null;
    privileges: IPrivileges;
    requiredActions?: Array<TLoginRequiredActions>;
}

const initialState: IState = {
    token: null,
    privileges: {
        anonymize: false,
        adminPanel: false,
        usersPanel: false,
        rolesPanel: false,
        manageAttributesSettings: false,
        setCoordinates: false,
        addRole: false,
        editRole: false,
        deleteRole: false,
        setRightUser: false,
        addUser: false,
        editUser: false,
        deleteUser: false,
        changeUserPassword: false,
        dashboardContext: false,
        reportsContext: false,
        reportSchedules: false,
        registersContext: false,
        alertsContext: false,
        locations: false,
        routes: false,
        bookmarks: false,
        archive: false,
        discoveryContext: false,
        tasks: false,
        addTask: false,
        editTask: false,
        deleteTask: false,
        objectRecalculation: false,
        alertSettings: false,
        addAlertSettings: false,
        editAlertSettings: false,
        registerEvents: false,
        manageGroups: false,
        RfidImport: false,
        eToll: false,
        eTollReadOnly: false,
        devicesManagement: false,
        alertSettingSms: false,
        eTollEditRegistrationData: false,
        xtrackServiceSystem: false,
        contextCustomerServiceCentre: false,
        accessInvisibleParams: false,
        experimentalFeatures: false,
        manageCustomerServiceCentre: false,
        anonymizeSwitchVisible: false,
        videoProcessingDebug: false,
        manageGroupGridViews: false,
        managePublicGridViews: false,
        ignorePermissionsInServiceCenter: false,
        showClientsWithLocationOnly: false,
        contextPlanning: false,
        editRoute: false,
        addRoute: false,
        deleteRoute: false,
        requireDeviceConfirmation: false,
        manageTrustedDevice: false,
        gpsPrioritizedDeviceManagement: false,
    },
};

interface IPrivilegeMapping {
    [key: string]: () => Partial<IPrivileges>;
}

const privilegeMapping: IPrivilegeMapping = {
    GETUSER: () => ({ adminPanel: true, usersPanel: true }),
    GETROLE: () => ({ adminPanel: true, rolesPanel: true }),
    MANAGEATTRIBUTESSETTINGS: () => ({ manageAttributesSettings: true }),
    SETCOORDINATES: () => ({ setCoordinates: true }),
    ADDROLE: () => ({ addRole: true }),
    EDITROLE: () => ({ editRole: true }),
    DELETEROLE: () => ({ deleteRole: true }),
    ADDUSER: () => ({ addUser: true }),
    SETRIGHTUSER: () => ({ setRightUser: true }),
    EDITUSER: () => ({ editUser: true }),
    DELETEUSER: () => ({ deleteUser: true }),
    CHANGEUSERPASSWORD: () => ({ changeUserPassword: true }),
    CONTEXTREPORTS: () => ({ reportsContext: true }),
    CONTEXTREGISTERS: () => ({ registersContext: true }),
    CONTEXTALERTS: () => ({ alertsContext: true }),
    CONTEXTMONITORING: () => ({ discoveryContext: true }),
    CONTEXTDASHBOARD: () => ({ dashboardContext: true }),
    ACCESSLASTSTATEREPORTS: () => ({ archive: true }),
    GETLOCATION: () => ({ locations: true }),
    ACCESSBOOKMARKS: () => ({ bookmarks: true }),
    GETROUTE: () => ({ routes: true }),
    GETTASK: () => ({ tasks: true }),
    ADDTASK: () => ({ addTask: true }),
    EDITTASK: () => ({ editTask: true }),
    DELETETASK: () => ({ deleteTask: true }),
    OBJECTRECALCULATION: () => ({ objectRecalculation: true }),
    GETALERTSETTING: () => ({ alertSettings: true }),
    ADDALERTSETTING: () => ({ addAlertSettings: true }),
    EDITALERTSETTING: () => ({ editAlertSettings: true }),
    REGISTEREVENTS: () => ({ registerEvents: true }),
    MANAGEGROUPS: () => ({ manageGroups: true }),
    RFIDIMPORT: () => ({ RfidImport: true }),
    ETOLL: () => ({ eToll: true }),
    ETOLLREADONLY: () => ({ eTollReadOnly: true }),
    DEVICESMANAGEMENT: () => ({ devicesManagement: true }),
    ALERTSETTINGSMS: () => ({ alertSettingSms: true }),
    ETOLLEDITREGISTRATIONDATA: () => ({ eTollEditRegistrationData: true }),
    XTRACKSERVICESYSTEM: () => ({ xtrackServiceSystem: true }),
    REPORTSCHEDULES: () => ({ reportSchedules: true }),
    CONTEXTCUSTOMERSERVICECENTRE: () => ({
        contextCustomerServiceCentre: true,
    }),
    MANAGECUSTOMERSERVICECENTRE: () => ({
        manageCustomerServiceCentre: true,
    }),
    MOVIEANONYMIZE: () => ({ anonymize: true }),
    OPTIONALANONYMIZEMOVIE: () => ({ anonymizeSwitchVisible: true }),
    VIDEOPROCESSINGDEBUG: () => ({ videoProcessingDebug: true }),
    ACCESSINVISIBLEPARAMS: () => ({ accessInvisibleParams: true }),
    EXPERIMENTALFEATURES: () => ({ experimentalFeatures: true }),
    MANAGEGROUPDATAVIEWS: () => ({ manageGroupGridViews: true }),
    MANAGEPUBLICDATAVIEWS: () => ({ managePublicGridViews: true }),
    IGNOREDATAVISIBILITYPERMISSIONSCUSTOMERSERVICECENTRE: () => ({
        ignorePermissionsInServiceCenter: true,
    }),
    SHOWCLIENTSWITHLOCATIONONLY: () => ({
        showClientsWithLocationOnly: true,
    }),
    CONTEXTPLANNING: () => ({
        contextPlanning: true,
    }),
    EDITROUTE: () => ({
        editRoute: true,
    }),
    ADDROUTE: () => ({
        addRoute: true,
    }),
    DELETEROUTE: () => ({
        deleteRoute: true,
    }),
    REQUIREDEVICECONFIRMATION: () => ({
        requireDeviceConfirmation: true,
    }),
    GPSPRIORITIZEDDEVICEMANAGEMENT: () => ({
        gpsPrioritizedDeviceManagement: true,
    }),
};

const authStorePath = `app/auth`;
const authPasswordStorePath = `${authStorePath}/password`;
const authMigrationStorePath = `${authStorePath}/migration`;

const duck = makeReduxDuck(authStorePath, initialState);

export const storeAuthData = duck.defineAction<{
    token: string;
    privileges: string[];
    requiredActions: Array<TLoginRequiredActions>;
}>('STORE_DATA', (state, { token, privileges, requiredActions }) => ({
    ...state,
    token,
    requiredActions,
    privileges: privileges
        .map((privilege) => privilegeMapping[privilege])
        .filter(Boolean) // for unrecognized privileges
        .map((func) => func())
        .reduce(
            (result, next) => ({
                ...result,
                ...next,
            }),
            initialState.privileges
        ) as IPrivileges,
}));

export default duck.getReducer();

const putPasswordStorePath = `put:${authPasswordStorePath}`;

export const changePassword = (
    password: IRestPutPassword,
    token: string,
    afterSucces: () => void,
    afterFailure: (error: any) => void
) =>
    makeRequest(
        putPasswordStorePath,
        () => restPutPasswordReset(password, token),
        (dispatch, data) => {
            // @ts-ignore
            if (afterSucces) {
                afterSucces();
            }
        },
        (dispatch, error) => {
            if (afterFailure) {
                afterFailure(error);
            }
        }
    );

const postMigrationStorePath = `post:${authMigrationStorePath}`;

export const migrationChangeEmail = (
    email: string,
    afterSucces?: () => void,
    afterFailure?: (error: any) => void
) =>
    makeRequest(
        postMigrationStorePath,
        () => restPostUserLoginMiration(email),
        (dispatch, data) => {
            // @ts-ignore
            if (afterSucces) {
                afterSucces();
            }
        },
        (dispatch, error) => {
            if (afterFailure) {
                afterFailure(error);
            }
        }
    );

export const migrationResendConfirmationEmail = (
    afterSucces?: () => void,
    afterFailure?: (error: any) => void
) =>
    makeRequest(
        postMigrationStorePath,
        () => restPostResendEmail(),
        (dispatch, data) => {
            // @ts-ignore
            if (afterSucces) {
                afterSucces();
            }
        },
        (dispatch, error) => {
            if (afterFailure) {
                afterFailure(error);
            }
        }
    );

export const migrationChangePassword = (
    newPassword: string,
    token: string,
    login: string,
    afterSucces: () => void,
    afterFailure: (error: any) => void
) =>
    makeRequest(
        postMigrationStorePath,
        () => restPostMigrationResetPassword(newPassword, token, login),
        (dispatch, data) => {
            // @ts-ignore
            if (afterSucces) {
                afterSucces();
            }
        },
        (dispatch, error) => {
            if (afterFailure) {
                afterFailure(error);
            }
        }
    );

const postPasswordStorePath = `post:${authPasswordStorePath}`;

export const resetPassword = (
    password: IRestPostPasswordReset,
    afterSucces: () => void,
    afterFailure: (error: Error) => void
) =>
    makeRequest(
        postPasswordStorePath,
        () => restPostPasswordReset(password),
        (dispatch, data) => {
            // @ts-ignore
            if (afterSucces) {
                afterSucces();
            }
        },
        (dispatch, error) => {
            if (afterFailure) {
                afterFailure(error);
            }
        }
    );

export const getPrivileges = (state: TRootState) => state.auth.privileges;

export const getRequiredActions = (state: TRootState) =>
    state.auth.requiredActions;
