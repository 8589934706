import React from 'react';

import MapPaneComponents from './MapPaneComponents';

import { areLastStatesEnabled } from '../../constants/AppConfig';

import ErrorBoundary from '../../components/ErrorBoundary';

import { useIsGridFullScreenOrAnalytics } from './selectors/index.hooks';
import useDiscoveryLayout from './hooks/useDiscoveryLayout';
import { TStyling } from './components/_utils/types';

interface IOwnProps {
    isMobile: boolean;
}

const getMobileMapPaneComponentsStyling = (): TStyling =>
    [
        {
            predicate: () => true,
            style: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 56,
                left: 0,
            },
        },
    ].find(({ predicate }) => predicate());

const getMapPaneComponentsStyling = (
    isBottomPaneVisible: boolean,
    isGridFullscreen: boolean,
    isPreviewPaneVisible: boolean,
    mobile: boolean
): TStyling => {
    return [
        {
            predicate: () => mobile,
            style: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        {
            predicate: () =>
                !isPreviewPaneVisible &&
                !isBottomPaneVisible &&
                !isGridFullscreen,
            style: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        {
            predicate: () =>
                !isPreviewPaneVisible &&
                isBottomPaneVisible &&
                !isGridFullscreen,
            style: {
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 'calc(50% + 10px)',
                left: 0,
            },
        },
        {
            predicate: () =>
                isPreviewPaneVisible &&
                isBottomPaneVisible &&
                !isGridFullscreen,
            style: {
                position: 'absolute',
                top: 0,
                right: '430px',
                bottom: 'calc(50% + 10px)',
                left: 0,
            },
        },
        {
            predicate: () =>
                isPreviewPaneVisible &&
                !isBottomPaneVisible &&
                !isGridFullscreen,
            style: {
                position: 'absolute',
                top: 0,
                right: '430px',
                bottom: 0,
                left: 0,
            },
        },
        {
            predicate: () => isBottomPaneVisible && isGridFullscreen,
            style: { visibility: 'hidden' },
        },
    ].find(({ predicate }) => predicate());
};

const MapPane = ({ isMobile }: IOwnProps) => {
    const { layoutClassName, isBottomPaneVisible, isPreviewPaneVisible } =
        useDiscoveryLayout();
    const isGridFullscreen = useIsGridFullScreenOrAnalytics();

    const mobileMapPaneComponentsStyling = getMobileMapPaneComponentsStyling();

    const mapPaneComponentsStyling = getMapPaneComponentsStyling(
        isBottomPaneVisible,
        isGridFullscreen,
        isPreviewPaneVisible,
        isMobile
    );

    const mapPaneStyling = isMobile
        ? mobileMapPaneComponentsStyling
        : mapPaneComponentsStyling;

    return (
        <>
            {mapPaneStyling && !isGridFullscreen && (
                <div style={mapPaneStyling.style}>
                    <ErrorBoundary>
                        <MapPaneComponents
                            layoutClassName={layoutClassName}
                            searchEnabled={areLastStatesEnabled()}
                        />
                    </ErrorBoundary>
                </div>
            )}
        </>
    );
};

export default MapPane;
